import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './views/authentication/login';
import SignUp from './views/authentication/signup';
import Index from './views/index';
import Home from './views/home';
import Create from './views/create';
import Quiz from './views/quiz';
import View from './views/view';
import './App.css'
import TopBar from '@/components/TopBar.tsx'
import { ThemeProvider } from "@/components/theme-provider"
import DocumentMeta from 'react-document-meta'

const meta = {
  title: 'StudiGPT',
  description: 'StudiGPT: Transform notes into custom GPT-powered quizzes for an enhanced, interactive study experience. Accelerate your earning today!',
  canonical: 'https://studigpt.io/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'study,gpt,gpt-3,gpt-4,notes,quiz,quizzes,ai'
    }
  }
};

const App: React.FC = () => {
  return (
    <DocumentMeta {...meta}>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/" element={<TopBar />}>
              <Route index element={<Index />} />
              <Route path="home" element={<Home />} />
              <Route path="create" element={<Create />} />
              <Route path="view" element={<View />} />
              <Route path="quiz/:quiz_id" element={<Quiz />} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </DocumentMeta>
  );
};

export default App
