import React, { useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import { Button } from '@/components/ui/button'
import { Textarea } from "@/components/ui/textarea"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import styles from './create.module.css';
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ThreeDots } from 'react-loader-spinner';
import { AlertTriangle } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"

export const Create: React.FC = () => {
  const [content, setContent] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [quizType, setQuizType] = useState<string>("mc");
  const [quizDifficulty, setQuizDifficulty] = useState<string>("easy");
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(undefined);
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, []);

  console.log(user);

  const handleSubmit = async () => {
    if (!user) {
      console.log("User not logged in.");
      return;
    }

    setLoading(true);
    
    const submission = {
      name: name,
      content: content,
      quizType: quizType,
      quizDifficulty: quizDifficulty
    }
    console.log(submission);

    const functions = getFunctions();
    const addNotes = httpsCallable(functions, 'addNotes');

    addNotes(submission).then((result) => {
      console.log(result.data);
      setLoading(false);
      // setSubmitted(true);
      navigate(`/view?id=${result.data}`);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
      setError(error.message);
    });
  }

  return (
    
    <div className={styles.container}>
      {error && (
        <div className="error">
        <Alert>
        <AlertTriangle />
        <AlertTitle>Oops!</AlertTitle>
        <AlertDescription>
          We had a problem on our end: {error}
        </AlertDescription>
      </Alert>
      </div>
      )}
      <div className={styles.header}>
        <h2 className="text-3xl font-bold tracking-tight">Create a new quiz.  ✏️</h2>
      </div>
      <div className={styles.innerContainer}>
        <div className={styles.leftSide}>
          <Textarea 
            maxLength={10000}
            placeholder='Type or paste your notes here.'
            value={content}
            onChange={e => setContent(e.target.value)}
          />
          <p className="text-sm text-muted-foreground">
          Your notes will be stored on our servers. <a href="#">Learn more</a>
          </p>
        </div>
        <div className={styles.rightSide}>
          <Label htmlFor="name">Name</Label>
          <Input 
            id="name"
            placeholder="Name"
            value={name}
            onChange={e => setName(e.target.value)} 
          />
          <Label htmlFor="quizType">Quiz Type</Label>
          <Tabs 
            id="quizType"
            defaultValue="mc"
            value={quizType}
            onValueChange={value => setQuizType(value)}
          >
            <TabsList>
              <TabsTrigger value="mc">🔠 Multiple Choice</TabsTrigger>
              <TabsTrigger value="tf">✅ True or ❌ False</TabsTrigger>
              <TabsTrigger value="wr">📝 Written Response</TabsTrigger>
            </TabsList>
            <TabsContent value="mc">
              <p className="text-sm text-muted-foreground">
                Create a 5-question multiple choice quiz.
              </p>
            </TabsContent>
            <TabsContent value="tf">
              <p className="text-sm text-muted-foreground">
                  Create a 5-question true or false quiz.
              </p>
            </TabsContent>
            <TabsContent value="wr">
              <p className="text-sm text-muted-foreground">
                  Create a 5-question written response quiz.
              </p>
            </TabsContent>
          </Tabs>
          <Label htmlFor='quizDificulty'>Quiz Dificulty</Label>
          <Tabs
            className={styles.tabs} 
            id="quizDificulty"
            defaultValue="easy"
            onValueChange={value => setQuizDifficulty(value)}
          >
            <TabsList>
              <TabsTrigger value="easy">😌Easy</TabsTrigger>
              <TabsTrigger value="medium">😣Medium</TabsTrigger>
              <TabsTrigger value="hard">😭Hard</TabsTrigger>
            </TabsList>
            <TabsContent value="easy">
              <p className="text-sm text-muted-foreground">
                I'm too young to die.
              </p>
            </TabsContent>
            <TabsContent value="medium">
              <p className="text-sm text-muted-foreground">
                Hurt me plenty.
              </p>
            </TabsContent>
            <TabsContent value="hard">
              <p className="text-sm text-muted-foreground">
                Nightmare!
              </p>
            </TabsContent>
          </Tabs>
          <Button id={styles.submit} onClick={handleSubmit} className="mt-4">
            {loading ? (
              <ThreeDots
              height="30" 
              width="30" 
              radius="9"
              color="#ffffff" 
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ></ThreeDots>
            ): (
              "Create Quiz"
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Create;