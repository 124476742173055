import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea"
import { Button } from '@/components/ui/button'
import styles from './quiz_question.module.css';
import { WrittenQuizQuestionProps } from '@/types/quiz';



export const WrittenQuizQuestion: React.FC<WrittenQuizQuestionProps> = ({ index, questionData, onAnswer, onNext }) => {
    const [isAnswered, setIsAnswered] = React.useState(false);
    const [isAnswerRevealed, setIsAnswerRevealed] = React.useState(false);
    const [answer, setAnswer] = React.useState("");

    const handleNextClick = () => {
        if (isAnswered) {
            onNext();
        } else {
            setIsAnswerRevealed(true);
            setIsAnswered(true);
            if (answer.length > 0) {
                onAnswer(true);
            } else {
                onAnswer(false);
            }
        }
    };

    return (
        <Card className={styles.questionCard}>
            <CardHeader>
                <CardTitle>Question {index}</CardTitle>
                <CardDescription>{questionData.question}</CardDescription>
            </CardHeader>
            <CardContent>
                <Textarea id={styles.answerInput} placeholder="Answer" onChange={(e) => setAnswer(e.target.value)} />
                
            </CardContent>
            <CardFooter className={styles.footer}>
                <Button id={styles.nextButton} onClick={handleNextClick}>{isAnswered ? "Next" : "Check"}</Button>
                {isAnswerRevealed && (
                    <div className={styles.answerReveal}>
                        🤔 Answer: {questionData.answer}
                    </div>
                )}
            </CardFooter>
        </Card>
    );
}

export default WrittenQuizQuestion;
