// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator  } from "firebase/firestore";
import { getFunctions, httpsCallable, connectFunctionsEmulator  } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDaiDFaFKAAsj7UVDY5jXx1mDwCbOMsIsI",
  authDomain: "studigpt-401306.firebaseapp.com",
  projectId: "studigpt-401306",
  storageBucket: "studigpt-401306.appspot.com",
  messagingSenderId: "26034895502",
  appId: "1:26034895502:web:cf9ac0746f5f94153c67df",
  measurementId: "G-5PY70NKC7J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// @ts-ignore
const analytics = getAnalytics(app);
// @ts-ignore
export const db = getFirestore(app);
const functions = getFunctions(app);
export const auth = getAuth(app);


if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export const helloWorld = httpsCallable(functions, 'helloWorld');
export const googleProvider = new GoogleAuthProvider();
export default app;