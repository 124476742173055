import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card";
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { AlertTriangle, SmilePlus } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './view.module.css';
import { ColorRing, Comment } from 'react-loader-spinner';

interface ResultData {
  score?: number;
}

export const View: React.FC = () => {
  const [user, setUser] = useState<User>();
  const [notes, setNotes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [scores, setScores] = useState<Record<string, string>>({});
  const [searchParams] = useSearchParams();
  const [noteId, setNoteId] = useState<string | null>(null);

  const auth = getAuth();

  const navigate = useNavigate(); 


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(undefined);
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const noteId = searchParams.get('id');
    if (noteId) {
      console.log("FOUND NOTE ID " + noteId);
      setNoteId(noteId);
    }
  }, [searchParams]);

  useEffect(() => {
      const getNotes = () => {
      setLoading(true);
      if(!user) {
        console.log("User not logged in.");
        return;
      }

      const functions = getFunctions();
      const getNotes = httpsCallable(functions, 'getNotes');

      getNotes().then((result) => {
        console.log(result.data);
        setNotes(result.data as any[]); //TODO: fix this
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      });
    }
    getNotes();
  }, [user]);

  const createQuiz = async (id: any) => {
    if (!user) {
      console.log("User not logged in.");
      return;
    }
    setGenerating(true);
    const functions = getFunctions();
    const createQuiz = httpsCallable(functions, 'createQuiz');

    createQuiz(id).then((result) => {
      const quiz_id = result.data;
      console.log(quiz_id);
      setGenerating(false);
      navigate(`/quiz/${quiz_id}`);
    })
    .catch((error) => {
      console.log(error);
      setError(error.message);
      setGenerating(false);
    });
  }

  useEffect(() => {
    if (notes.length > 0) {
      notes.forEach(note => {
        getLastScore(note.id);
      });
    }
  }, [notes]);

  const getLastScore = async (id: any) => {
    if (!user) {
      console.log("User not logged in.");
      return;
    }
    const functions = getFunctions();
    const getScore = httpsCallable(functions, 'getScore');

    try {
      const result = await getScore(id);
      const data = result.data as ResultData;
      console.log(data);
      if (typeof(data.score) === "number") {
        const score = data.score;
        setScores(prevScores => ({ ...prevScores, [id]:  score* 100 + "%" }));
      }
      else {
        setScores(prevScores => ({ ...prevScores, [id]: "N/A" }));
        return;
      }
      
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        setError(error.message);
      }
      setScores(prevScores => ({ ...prevScores, [id]: "N/A" }));
    }
  }

  if (generating) {
    return (
      <div className={styles.container}>
        <Card className={styles.viewCard}>
          <CardHeader>
            <CardTitle>Generating Quiz</CardTitle>
            <CardDescription>Your robot friend is generating a quiz from your notes...</CardDescription>
          </CardHeader>
          <CardContent className={styles.generatingContent}>
            <Comment
                visible={true}
                height="80"
                width="80"
                ariaLabel="comment-loading"
                wrapperStyle={{}}
                wrapperClass="comment-wrapper"
                color="#fff"
                backgroundColor="#5bb9e1"
              />
          </CardContent>
        </Card>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {error && (
        <div className="error">
          <Alert>
          <AlertTriangle />
          <AlertTitle>Oops!</AlertTitle>
          <AlertDescription>
            We had a problem on our end: {error}
          </AlertDescription>
          </Alert>
        </div>
      )}

      {noteId && (
        <div className="error">
        <Alert>
        <SmilePlus />
        <AlertTitle>Yay!</AlertTitle>
        <AlertDescription>
          Your note has been added!
        </AlertDescription>
        </Alert>
      </div>
      )}
  
      <Card className={styles.viewCard}>
        <CardHeader>
          <CardTitle>Your Notes</CardTitle>
          <CardDescription>Here are your uploaded notes.</CardDescription>
        </CardHeader>
      <CardContent>
        {loading ? (
          <div className={styles.loading}>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#5bb9e1', '#5bb9e1', '#5bb9e1', '#5bb9e1', '#5bb9e1']}
            />
            <p>Loading your notes...</p>
          </div>
        ): (
        <Table>
          <TableCaption>A list of your notes.</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead>Note Name</TableHead>
              <TableHead>Note ID</TableHead>
              <TableHead></TableHead>
              <TableHead>Last Score</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {notes.map((note, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{note.name}</TableCell>
                  <TableCell>{note.id}</TableCell>
                  <TableCell>
                    <Button onClick={() => {createQuiz(note.id)}}>
                        Generate Quiz
                    </Button>
                  </TableCell>
                  <TableCell>
                    {scores[note.id] ||
                    <ColorRing
                      visible={true}
                      height="40"
                      width="40"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#5bb9e1', '#5bb9e1', '#5bb9e1', '#5bb9e1', '#5bb9e1']}
                    />}
                </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        )}
      </CardContent>
      </Card>
    </div>
  )
}

export default View;