import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import QuizQuestion from "@/components/QuizQuestion";
import WrittenQuizQuestion from "@/components/WrittenQuizQuestion.tsx";
import styles from './quiz.module.css';
import { Progress } from "@/components/ui/progress"
import { Button } from '@/components/ui/button'
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.ts"
import { QuizData, WrittenQuizData } from "@/types/quiz"
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { AlertTriangle } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { getFunctions, httpsCallable } from "firebase/functions";
import { WrittenQuestion, Question } from '@/types/quiz';


export const Quiz: React.FC = () => {
    const [user, setUser] = useState<User>();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [incorrectAnswers, setIncorrectAnswers] = useState(0);
    const { quiz_id } = useParams<{ quiz_id: string }>();
    const [quizData, setQuizData] = useState<QuizData | WrittenQuizData |null>(null);
    const [loading, setLoading] = useState(true);
    const [answered, setAnswered] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user);
          } else {
            setUser(undefined);
            navigate('/login');
          }
        });
        return () => unsubscribe();
      }, []);
    

    useEffect(() => {
        const fetchQuiz = async () => {
            if(!user) {
                console.log("User not logged in.");
                return;
              }
            if (quiz_id) {
                try {
                    const quizRef = doc(db, "generated_quizzes", quiz_id);
                    const quiz = await getDoc(quizRef);
                    if (quiz.exists()) {
                        setQuizData(quiz.data() as QuizData | WrittenQuizData);
                        console.log(quiz.data());
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        setError(error.message);
                        console.log("Error getting document:", error.message);
                    } else {
                        setError("An unexpected error occurred");
                        console.log("Error getting document:", error);
                    }
                }
            }
            setLoading(false);
        }
        fetchQuiz();
    }, [quiz_id, user]);


    const submitScore = async () => {
        if (!user) {
            console.log("User not logged in.");
            return;
        }
        const functions = getFunctions();
        const submitScore = httpsCallable(functions, 'submitScore');

        submitScore({
            quiz_id: quiz_id,
            note_id: quizData?.noteId,
            score: correctAnswers / quizData!.questions.length,
        }).then((result) => {
            console.log(result.data);
        })
        .catch((error) => {
            console.log(error);
            setError(error.message);
        });
    }

    useEffect(() => {
        if (currentIndex >= (quizData?.questions.length ?? 0)) {
            submitScore();
        }
    }, [currentIndex, quizData]);

    const handleAnswer = (isCorrect: boolean) => {
        setAnswered(true);
        if (isCorrect && !answered) {
            setCorrectAnswers(correctAnswers + 1);
        } else if (!isCorrect && !answered) {
            setIncorrectAnswers(incorrectAnswers + 1);
        }  
    };

    const goToNextQuestion = () => {
        setAnswered(false);
        setCurrentIndex(currentIndex + 1);
    }

    const resetQuiz = () => {
        setCurrentIndex(0);
        setCorrectAnswers(0);
        setIncorrectAnswers(0);
    }

    const renderQuizQuestion = () => {
        if (quizData == null) {
            return <></>;
        }
        if (quizData?.type == "wr") {
            const writtenQuestionData = quizData.questions[currentIndex] as WrittenQuestion;
            return (
                <WrittenQuizQuestion
                    key={currentIndex}
                    index={currentIndex + 1}
                    questionData={writtenQuestionData}
                    onAnswer={handleAnswer}
                    onNext={goToNextQuestion}
                />
            );
        } else {
            const questionData = quizData.questions[currentIndex] as Question;
            return (
                <QuizQuestion
                    key={currentIndex}
                    index={currentIndex + 1}
                    questionData={questionData}
                    onAnswer={handleAnswer}
                    onNext={goToNextQuestion}
                />
            );
        }
    }

    if(loading) {
        return (
            <div className={styles.center}>
                <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#5bb9e1', '#5bb9e1', '#5bb9e1', '#5bb9e1', '#5bb9e1']}
                />
                <p>Fetching quiz...</p>
            </div>
        )
    }

    if(!quizData) {
        return (
            <div className={styles.center}>
                    {error && (
                    <div className="error">
                    <Alert>
                    <AlertTriangle />
                    <AlertTitle>Oops!</AlertTitle>
                    <AlertDescription>
                    We had a problem on our end: {error}
                    </AlertDescription>
                </Alert>
                </div>
                )}
                <p>Quiz not found :(</p>
            </div>
            )
    }

    if (currentIndex >= quizData.questions.length) {
        return <div className={styles.center}>
            You've completed the quiz! Correct: {correctAnswers} Incorrect: {incorrectAnswers}
            <Button onClick={resetQuiz}>
                Restart
            </Button>
            <Link to="/">
            <Button>
                Go Home
            </Button>
            </Link>
        </div>;

    }

    return (
        <div className={styles.container}>
            {error && (
                <div className="error">
                <Alert>
                <AlertTriangle />
                <AlertTitle>Oops!</AlertTitle>
                <AlertDescription>
                We had a problem on our end: {error}
                </AlertDescription>
            </Alert>
            </div>
            )}
            <div className={styles.progressContainer}>
                <div className="text-2xl font-bold">{currentIndex+1}/{quizData.questions.length}</div>
                <Progress value={(currentIndex+1)/quizData.questions.length * 100} />
            </div>
            <div className={styles.quizContainer}>
                {renderQuizQuestion()}
            </div>
        </div>
    )
}
    
export default Quiz;