import React, { useEffect, useState } from 'react';
import { auth } from '../firebase.ts';

export const Home: React.FC = () => {
    const [displayName, setDisplayName] = useState<string | null>(null);

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
          setDisplayName(user.displayName || "Anonymous");
        }
      }, []);

    return (
    <div>
      {displayName ? <h1>Welcome, {displayName}!</h1> : <h1>Loading...</h1>}
    </div>
    );
};

export default Home;