import React, {useState} from 'react';
import { Button } from '@/components/ui/button'
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import styles from './login.module.css';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile, browserLocalPersistence } from 'firebase/auth';
import { auth } from '../../firebase.ts';

export const SignUp: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [error, setError] = useState<string | null>(null);

    auth.setPersistence(browserLocalPersistence)
    .then(() => {
        console.log("Persistence set to local.");
    })
    .catch((error) => {
        console.log("Error setting persistence to local: ", error);
    });


    const prettyPrintFirebaseAuthError = (errorCode: string) => {
        if (errorCode === "auth/invalid-login-credentials")
            return "Invalid email or password";
        else
            return "An error occured. Please try again."
    }

    const onSignUp = (e: React.FormEvent) => {
        console.log("Sign Up button clicked")
        e.preventDefault();
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);

            return updateProfile(user, {
                displayName: name
            }).then(() => {
                navigate('/');
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setError(prettyPrintFirebaseAuthError(errorCode));
        });
    }

    return (
        <div className={styles.container}>
            {/* Left Side */}
            <div className={styles.leftSide}>
                <div className={styles.titleContent}>
                    <h1 className={styles.title}>StudiGPT</h1>
                    <p className={styles.typewriter}>
                        <span className={styles.content}>A GPT-powered studying platform. 🧠🐘</span>
                    </p>
                </div>
                <div className={styles.footer}>
                    <p className={styles.footerText}>StudiGPT: A project by Chris Grams</p>
                </div>
            </div>

            {/* Right Side */}
            <div className={styles.rightSide}>
                <div className={styles.loginWidget}>
                    <h1>Sign Up</h1>
                    <div className={styles.errorMessage}><span>{error}</span></div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="name" className={error ? styles.errorInput : ''}>Name</Label>
                        <Input 
                            type="name"
                            id="name"
                            required
                            placeholder="Name"
                            onChange={(e) => setName(e.target.value)}
                            className={error ? styles.errorInput : ''} // Set error style only if error input is not empty
                        />
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="email" className={error ? styles.errorInput : ''}>Email</Label>
                        <Input 
                            type="email"
                            id="email"
                            required
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            className={error ? styles.errorInput : ''} // Set error style only if error input is not empty
                        />
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="password" className={error ? styles.errorInput : ''}>Password</Label>
                        <Input 
                            type="password"
                            id="password"
                            required
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            className={error ? styles.errorInput : ''} // Set error style only if error input is not empty
                        />
                    </div>
                    <div className={styles.loginButtons}>
                        <Button onClick={onSignUp}>Sign Up</Button>
                        <Button>Back to login</Button>
                    </div>
                </div>
        </div>
        </div>
    )
}

export default SignUp;