import React, { useEffect } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { auth } from '../firebase.ts';
import { Pencil, ScrollText, CreditCard } from 'lucide-react';
import styles from './index.module.css';

export const Index: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/login');     
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Card>
          <CardHeader>
            <CardTitle>Welcome to StudiGPT!</CardTitle>
          </CardHeader>
          <CardContent className={styles.featuresCard}>
            <p>Some awesome features of StudiGPT:</p>
            <ul className={styles.features}>
              <li><Pencil /><p>Upload your own notes.</p></li>
              <li><ScrollText /><p>Have GPT create a quiz from your notes.</p></li>
              <li><CreditCard /><p>*Coming Soon* Auto generated notecards from your notes.</p></li>
            </ul>
          </CardContent>
        </Card>
      </div>
      <div className={styles.right}>
        <Link to="/create">
          <Card className={`${styles.optionCard} ${styles.gradient1}`}>
            <CardContent>
              <h1>Create a note</h1>
            </CardContent>
            <CardFooter><p>Get started by creating a note</p></CardFooter>
          </Card>
        </Link>
        <Link to="/view">
        <Card className={`${styles.optionCard} ${styles.gradient2}`}>
          <CardContent>
            <h1>View notes</h1>
          </CardContent>
          <CardFooter><p>View your existing notes</p></CardFooter>
        </Card>
        </Link>
      </div>
    </div>
  );
}

export default Index;