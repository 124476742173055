import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { Progress } from "@/components/ui/progress";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import { ModeToggle } from "@/components/mode-toggle";
import styles from './topbar.module.css';

interface UserQuota {
    note_count: number;
    quiz_count: number;
    max_note_count: number;
    max_quiz_count: number;
    tier: string;
}

const TopBar = () => {
  const [userPhoto, setUserPhoto] = useState<string | undefined>(undefined);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [userInitials, setUserInitials] = useState<string | undefined>(undefined);
  const [noteQuota, setNoteQuota] = useState(0);
  const [noteMaxQuota, setMaxNoteQuota] = useState(0);
  const [quizQuota, setQuizQuota] = useState(0);
  const [quizMaxQuota, setMaxQuizQuota] = useState(0);
  const [tier, setTier] = useState<string | undefined>(undefined);


  const getUserInitials = (name: string) => {
    const words = name.split(' ');
    const initials = words.map((word) => word.charAt(0)).join('');
    return initials.toUpperCase();
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserPhoto(user.photoURL || undefined);
        setUserName(user.displayName || undefined);
        setUserInitials(getUserInitials(user.displayName || ''));
        
        const functions = getFunctions();
        const getUserQuota = httpsCallable(functions, 'getUserQuota');

        getUserQuota().then((result) => {
            const data = result.data as UserQuota;
            console.log(data);
            setNoteQuota(data.note_count);
            setQuizQuota(data.quiz_count);
            setMaxNoteQuota(data.max_note_count);
            setMaxQuizQuota(data.max_quiz_count);
            setTier(data.tier);
        })
        
      } else {
        setUserPhoto(undefined);
        setUserName(undefined);
        setUserInitials(undefined);
      }
    });
  }, []);

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };

  return (<>
      <div className={styles.main}>
        <h1 className={styles.logo}><Link to='/' >StudiGPT</Link></h1>
        <ModeToggle/>
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger>
                <h1 className={styles.name}>{userName}</h1>
                <Avatar>
                  <AvatarImage src={userPhoto} />
                  <AvatarFallback>{userInitials}</AvatarFallback>
                </Avatar>
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <div className={styles.quota}>
                    <h1>Quotas</h1>
                    <h2>Tier: <b>{tier}</b></h2>
                    <h3>Notes: {noteQuota}/{noteMaxQuota}</h3>
                    <Progress className={styles.green} value={noteQuota/noteMaxQuota*100} />
                    <h3>Quizzes: {quizQuota}/{quizMaxQuota}</h3>
                    <Progress className={styles.purple} value={quizQuota/quizMaxQuota*100} />
                </div>
                <hr />
                <ul className={styles.menu}>
                  <li className={styles.menuItem}>
                    <NavigationMenuLink href="/">Home</NavigationMenuLink>
                  </li>
                  <li className={styles.menuItem}>
                    <NavigationMenuLink href="/create">Create Note</NavigationMenuLink>
                  </li>
                  <li className={styles.menuItem}>
                    <NavigationMenuLink href="/view">View Notes</NavigationMenuLink>
                  </li>
                  <li className={styles.menuItem}>
                    <NavigationMenuLink onClick={handleLogout} href=''>Logout</NavigationMenuLink>
                  </li>
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      <Outlet />
      </>
  );
};

export default TopBar;
