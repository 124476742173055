import React from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import confetti from 'canvas-confetti';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from '@/components/ui/button'
import styles from './quiz_question.module.css';
import { QuizQuestionProps } from '@/types/quiz';


const createQuestionSchema = (options: string[]) => {
    const tupleOptions = [options[0], ...options.slice(1)] as [string, ...string[]]; // hack to make z.enum not typescript error

    return z.object({
      answer: z.enum(tupleOptions, {
        required_error: "You need to select an answer.",
      }),
    });
};


export const QuizQuestion: React.FC<QuizQuestionProps> = ({ index, questionData, onAnswer, onNext }) => {
    const [feedback, setFeedback] = React.useState<'correct' | 'incorrect' | 'none'>('none');
    const [isAnswered, setIsAnswered] = React.useState(false);
    const options = Object.keys(questionData.selections);
    const form = useForm({
    resolver: zodResolver(createQuestionSchema(options)),
    });

    const handleNextClick = () => {
        form.handleSubmit((data) => {
            if(isAnswered) {
                onNext();
                return;
            }
            if (data.answer === questionData.answer) {
                setFeedback('correct');
                setIsAnswered(true);
                confetti({
                    particleCount: 100,
                    spread: 70,
                    origin: { y: 0.6 }
                });
                onAnswer(true);
            } else {
                setFeedback('incorrect');
                onAnswer(false);
            }
        })();
    };

    return (
        <Card className={styles.questionCard}>
            <CardHeader>
                <CardTitle>Question {index}</CardTitle>
                <CardDescription>{questionData.question}</CardDescription>
            </CardHeader>
            <CardContent>
                <form>
                <RadioGroup
                    onValueChange={value => form.setValue('answer', value)}
                    defaultValue={form.watch('answer')}
                >
                    {options.map(option => (
                    <div key={option} className="flex items-center space-x-2">
                        <RadioGroupItem value={option} />
                        <Label>{questionData.selections[option]}</Label>
                    </div>
                    ))}
                </RadioGroup>
                </form>
            </CardContent>
            <CardFooter className={styles.footer}>
                <div className={styles.messageContainer}>
                    {feedback === 'correct' && <p className="text-green-500">Correct!</p>}
                    {feedback === 'incorrect' && <p className="text-red-500">Incorrect :( Please try again.</p>}
                </div>
                <Button id={styles.nextButton} onClick={handleNextClick}>{isAnswered ? "Next" : "Check"}</Button>
            </CardFooter>
        </Card>
    );
}

export default QuizQuestion;
